const colors = {
  transparent: 'transparent',
  white: '#FFFFFF',
  gray: {
    25: '#F8F9FC',
    50: '#E1E7F1',
    75: '#F3F3F3',
    100: '#C4C4C4',
    200: '#EDEDED',
    300: '#E0E0E0',
    600: '#4A5568',
  },
  blue: {
    d: '#1E486F',
    50: '#EBF5FF',
    75: '#F0F1F3',
    100: '#61BAF4',
    125: '#CCEBFF',
    150: '#EFFAFF',
    400: '#718096',
    450: '#4D638A',
    500: '#1E4176',
    600: '#26B3E9',
    700: '#2b6cb0',
    750: '#225B90',
    800: '#1E4176',
  },
  green: {
    light: '#F1F8EC',
    extraLight: '#E4EEDC',
    text: '#41592D',
    400: '#03633B',
    500: '#24A668',
    700: '#16A368',
    900: '#04653D',
    1000: '#0B462A',
  },
  warning: {
    light: '#FFF0BC',
    extraLight: '#FFF7DC',
    text: '#863800',
    400: '#B20136',
  },
  yellow: {
    d: '#863800',
    dark: '#FFDB5F',
    extraLight: '#FFF7DC',
    hc: '#FFFF01',
    100: '#FFDB5E',
    800: '#97470E',
  },
  orange: {
    extraLight: '#FFF1DC',
    dark: '#FFBE5E',
    300: '#FEDEBC',
    400: '#F78E20',
    500: '#F58D22',
    600: '#FF9A03',
    900: '#A40A00',
  },
  olive: {
    1: '#41592D',
    22: '#5B7944',
    50: '#F1F8EC',
    75: '#C6D7B9',
    100: '#41592D',
  },
  danger: {
    1: '#FFD8D8',
    light: '#FFF8F7',
    extraLight: '#FFEEEC',
    text: '#A80000',
    75: '#FFA5A5',
    100: '#FFF5F5',
    150: '#FFF8F8',
    200: '#FEE6D7',
    300: '#E53E3E',
    400: '#fcd5d5',
    500: '#B51D32',
    600: '#F65763',
    700: '#E00027',
    800: '#C10013',
    900: '#A80000',
  },
  secondary: {
    50: '#F8F9FCFF',
    100: '#F1F5FBFF',
    200: '#E1E7F0FF',
    300: '#C8D1DEFF',
    400: '#A0AEC2FF',
    500: '#8A99AFFF',
    700: '#64758EFF',
    800: '#45546AFF',
    900: '#2B3646FF',
    950: '#1A222E',
    1000: '#0A0F16FF',
  },
  primary: {
    30: '#E4EDF8FF',
    50: '#D9E3F0FF',
    100: '#B7C5DEFF',
    200: '#7F94B4FF',
    300: '#6D80A1FF',
    400: '#586F96FF',
    500: '#4B638EFF',
    600: '#415984FF',
    700: '#36507FFF',
    800: '#1E4176FF',
    900: '#1E365FFF',
    1000: '#1A2C49FF',
  },
  basic: {
    400: '#C5D2E4',
    100: '#D9D9D9',
  },
  grayAlpha: {
    500: 'rgba(236, 236, 236, 0.85)',
  },
  info: {
    100: '#F4FAFFFF',
    200: '#E0F7FFFF',
    300: '#C0EFFFFF',
    400: '#97E2FFFF',
    500: '#4ECDFEFF',
    600: '#26B3E9FF',
    700: '#149CD2FF',
    800: '#0784B5FF',
    1000: '#612BA6',
  },
  success: {
    100: '#F5FFFB',
    700: '#24A668',
    800: '#04653D',
    900: '#0B462A',
  },
  calendar: {
    violet: {
      1: '#583F79',
      2: '#664B88',
      extraLight: '#EFEDFF',
    },
    blue: {
      100: '#F2F9FF',
      800: '#225B90',
    },
    yellow: {
      d: '#863800',
      light: '#FFF0BC',
      extraLight: '#FFF7DC',
      800: '#97470E',
    },
    orange: {
      extraLight: '#FFF1DC',
      d: '#A40A00',
    },
  },
  teal: {
    500: '#72E9FC',
  },
  sky: {
    500: '#4ECDFE',
    900: '#00709D',
  },
  fuchsia: {
    400: '#FFC5F2',
    500: '#FFA5EB',
  },
};

export default colors;
