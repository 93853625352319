import { useToast } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isSafari } from '@lon/shared/utils';

const useSwitchSafariToChromeNotification = (showNotification = true) => {
  const toast = useToast();
  const { t } = useTranslation();

  useEffect(() => {
    if (!showNotification) {
      return;
    }

    if (isSafari) {
      toast({
        title: t('switchFromSafariToChrome.title'),
        description: t('switchFromSafariToChrome.description'),
        variant: 'warning-light',
        duration: null,
        isClosable: true,
      });
    }
  }, [showNotification]);
};

export default useSwitchSafariToChromeNotification;
