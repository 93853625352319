export const downloadFile = async ({
  url,
  filename = '',
  mode,
}: {
  url: string;
  filename: string;
  mode: 'fetch' | 'new-tab';
}) => {
  if (mode === 'fetch') {
    const response = await fetch(url);
    const blob = await response.blob();
    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  }

  if (mode === 'new-tab') {
    window.open(url, '_blank', `name=${filename}`);
  }
};
