import { defaultDistrictSettings } from '@lon/shared/constants';
import { ApplicationEnum, DistrictPermission } from '@lon/shared/enums';
import {
  BehaviourEnum,
  GetAclApplicationsQuery,
  GetDistrictSettingsQuery,
  SetupStatusEnum,
} from '@lon/shared/requests';
import { ModuleEnum, OperationEnum } from '@lon/shared/types';

const wizardModule = {
  name: ModuleEnum.wizard,
  operations: [
    {
      name: OperationEnum.view,
      behaviour: BehaviourEnum.Display,
    },
  ],
};

type MergeAdminAclSettings = (
  application: any,
  data?: GetAclApplicationsQuery,
  settings?: GetDistrictSettingsQuery
) => any;

export const mergeAdminAclSettings: MergeAdminAclSettings = (
  application,
  data,
  settings = defaultDistrictSettings
) => {
  if (application === ApplicationEnum.ADMIN) {
    const wizardCompleted =
      settings?.districtSetupStatus?.status === SetupStatusEnum.Completed;

    return [
      ...(data?.aclApplications || []),
      {
        modules: wizardCompleted ? [] : [wizardModule],
        name: 'DistrictSettings',
        permissionName: DistrictPermission.ADMIN,
      },
    ];
  }

  return data?.aclApplications;
};
