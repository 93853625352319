import { Box, Flex, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  generatePath,
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { RootState, routes, setError } from '@lon/login/configs';
import { AsyncOutlet } from '@lon/shared/components';
import { useMediaQuery, useQueryParams } from '@lon/shared/hooks';

export const LoginContainer: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams({});
  const [params] = useQueryParams();
  const [isLargerThan480] = useMediaQuery('(min-width: 480px)');
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const { qrInstance } = useSelector((state: RootState) => state.qrCode);
  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const { redirectTo } = params;

  React.useEffect(() => {
    if (state?.districtId && state?.stateValue) {
      return;
    }

    try {
      const savedState = JSON.parse(localStorage.getItem('state') || '""');

      if (
        savedState &&
        pathname === routes.root &&
        !state?.errorTitle &&
        !state?.errorBody &&
        !redirectTo
      ) {
        navigate(`${routes.root}?state=${savedState}`);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const tabs = () => {
    if (isLargerThan768) {
      return [
        t('login.loginTab'),
        t('login.loginQrTab'),
        t('login.loginPreview'),
      ];
    } else if (isLargerThan480) {
      return [
        t('login.loginTabMobile'),
        t('login.loginQrTabMobile'),
        t('login.loginPreviewMobile'),
      ];
    } else {
      return [
        t('login.loginTabMobile'),
        t('login.loginQrTabMobile'),
        t('login.loginPreviewMobile'),
      ];
    }
  };

  const isMainSelected = useMatch(routes.root);
  const isQrSelected = useMatch(routes.login.qrCode);
  const isPreviewSelected = pathname.includes('preview');

  const stateSearchParam = searchParams.get('state');

  const isTabSelected = (i: number) => {
    const paths = [isMainSelected, isQrSelected, isPreviewSelected];
    return paths[i];
  };

  const handleTabChange = (index: number) => {
    if (index === 1) {
      navigate(routes.login.qrCode, {
        state: { searchParams: stateSearchParam },
      });
    } else {
      if (qrInstance && qrInstance?.isScanning) {
        qrInstance?.stop();
        qrInstance?.clear();
      }
      if (index === 2) {
        navigate(generatePath(routes.login.preview, { urlCode: undefined }));
      } else {
        navigate(
          state?.searchParams
            ? `${routes.root}?state=${state?.searchParams}`
            : routes.root
        );
      }
    }
    dispatch(setError({ err: null }));
  };

  return (
    <Flex
      minWidth={{ base: '100%', sm: '480px', md: '712px' }}
      maxWidth={{ base: '100%', sm: '480px', md: '712px' }}
      minH="525px"
      zIndex="10"
      px={{ base: 5, sm: 10, md: 12 }}
      py={{ base: 3, sm: 6, md: 10 }}
      id="login"
      gap={8}
      direction="column"
      boxShadow=" 0 4px 4px rgba(0, 0, 0, 0.25)"
      background="linear-gradient(180deg, #1E417C 26.12%, rgba(30, 65, 118, 0.8) 72.14%)"
    >
      <Flex direction="column" gap={2}>
        <Flex justify="space-between" align="center">
          <Image
            width="108px"
            height="73px"
            alt="STEM, Focused curriculum"
            src="login/assets/stem_focused_curriculum.svg"
          />
          <Text
            as="h1"
            fontSize="1.5rem"
            variant="n3"
            color="white"
            letterSpacing="3px"
          >
            {t('login.heading')?.toLocaleUpperCase()}
          </Text>
        </Flex>
        {isMainSelected || isQrSelected || isPreviewSelected ? (
          <Flex
            flexDir="row"
            align="center"
            justify="space-between"
            borderBottomWidth="1px"
            borderBottomColor="white"
            height="42px"
            role="tablist"
            aria-label={t('login.loginNavigation')}
          >
            {tabs()?.map((tab, i) => (
              <Box
                key={tab}
                tabIndex={0}
                onClick={() => handleTabChange(i)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleTabChange(i);
                  }
                }}
                py={2}
                cursor="pointer"
                data-testid={`tab${i}`}
                role="tab"
                id={`tab-${i}`}
                aria-controls={`tab-${i}-panel`}
                aria-selected={!!isTabSelected(i)}
              >
                <Text
                  variant="h6"
                  display="inline"
                  fontWeight={400}
                  pb={2}
                  textTransform="uppercase"
                  color={isTabSelected(i) ? 'white' : 'primary.100'}
                  borderBottomWidth={isTabSelected(i) ? '4px' : '0'}
                  borderBottomColor={isTabSelected(i) ? 'white' : 'none'}
                >
                  {tab}
                </Text>
              </Box>
            ))}
          </Flex>
        ) : null}
      </Flex>
      <Flex w="100%" flexGrow="1">
        <AsyncOutlet />
      </Flex>
    </Flex>
  );
};
