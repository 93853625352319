import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ActionType, createReducer } from 'typesafe-actions';
import { PlanType, ScopesListTypes } from '@lon/shared/types';
import * as actions from './actions';

type ScopesActions = ActionType<typeof actions>;

const imageTag = process.env?.['NX_IMAGE_TAG'] || '';

const scopesPersistConfig = {
  key: `scopes[${imageTag}]`,
  storage,
  whitelist: ['listType'],
};

const initialState: {
  listType: ScopesListTypes;
  planData: PlanType;
} = {
  listType: 'tile',
  planData: {
    scopeId: '',
    scopePlanId: '',
    curriculumArea: '',
    grade: '',
    elements: [],
    version: 0,
  },
};

const reducer = createReducer<typeof initialState, ScopesActions>(initialState)
  .handleAction(actions.setListType, (state, { payload: listType }) => ({
    ...state,
    listType,
  }))
  .handleAction(actions.setPlanData, (state, { payload: PlanData }) => ({
    ...state,
    planData: PlanData,
  }));

export default persistReducer(scopesPersistConfig, reducer);
