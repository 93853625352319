export const navigateNext = <
  T extends { disabled: boolean } = { disabled: boolean }
>(
  list: T[],
  startIndex: number
): { item: T; index: number } => {
  for (let i = startIndex + 1; i < list.length; i += 1) {
    if (list[i] && !list[i].disabled) return { item: list[i], index: i };
  }
  for (let i = 0; i < startIndex; i += 1) {
    if (list[i] && !list[i].disabled) return { item: list[i], index: i };
  }

  return { item: list[startIndex], index: startIndex };
};

export default navigateNext;
