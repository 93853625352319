import { useEffect } from 'react';
import { ApplicationEnum } from '@lon/shared/enums';
import { clearStorage, parseJSON, patchLocalStorage } from '@lon/shared/utils';

const imageTag = process.env?.['NX_IMAGE_TAG'] || '0.75-rc.13';

const persistsByApplication = {
  [ApplicationEnum.TEACHER_SUIT]: ['persist:scopes', 'persist:root'],
  [ApplicationEnum.PLATFORM_ADMIN]: ['persist:platform-admin'],
  [ApplicationEnum.ADMIN]: ['persist:admin'],
};

const useInvalidateStorages = ({
  application,
}: {
  application: Extract<
    ApplicationEnum,
    | ApplicationEnum.PLATFORM_ADMIN
    | ApplicationEnum.ADMIN
    | ApplicationEnum.TEACHER_SUIT
  >;
}) => {
  useEffect(() => {
    const previousImageTag = parseJSON<Record<ApplicationEnum, string>>(
      localStorage.getItem('imageTagByApplication') || ''
    );

    console.log({
      application,
      imageTag,
      savedTag: previousImageTag?.[application],
    });

    if (previousImageTag?.[application] !== imageTag) {
      clearStorage({
        blacklist: persistsByApplication[application],
      });
    }
    patchLocalStorage('imageTagByApplication', { [application]: imageTag });
  }, []);
};

export default useInvalidateStorages;
