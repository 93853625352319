import { Flex, Link, Text } from '@chakra-ui/react';
import React from 'react';

export const MessageContent: React.FC<{ feature: string }> = ({ feature }) => {
  return (
    <Flex direction="column" gap={3} alignItems="start">
      <Text variant="s1" textAlign="left">
        {feature} function is not available.
      </Text>
      <Text variant="s2" textAlign="left">
        Your access is limited to curriculum review. To unlock full platform
        functionality, please{' '}
        <Link
          textDecoration="underline"
          href="mailto:adoptions@acceleratelearning.com"
        >
          contact Accelerate Learning Inc.
        </Link>
      </Text>
    </Flex>
  );
};
