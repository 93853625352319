import { Asterisk } from '../../asterisk';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/react';
import React from 'react';
import { inputLayoutTypes } from './duck';

const InputLayout: React.FC<inputLayoutTypes.InputLayoutProps> = ({
  id,
  label,
  input,
  description,
  errorMessage,
  isRequired,
  formLabelProps,
  variant = 'base',
  rightLabel,
  errorPosition,
  hideErrorMessage = false,
  formErrorMessageProps,
  ...props
}) => {
  return (
    <FormControl
      id={id}
      isInvalid={!!errorMessage}
      isRequired={isRequired}
      variant={props.isDisabled ? 'disabled' : variant || 'base'}
      {...props}
    >
      {(rightLabel || label) && (
        <Flex
          justifyContent="space-between"
          marginBottom={2}
          alignItems="center"
        >
          {label && (
            <FormLabel
              marginBottom="0px !important"
              lineHeight="1.625rem !important"
              width="full"
              color={
                props?.isDisabled
                  ? 'secondary.500'
                  : variant === 'dark' || variant === 'empty-dark'
                  ? 'white'
                  : 'primary.800'
              }
              requiredIndicator={
                <Asterisk
                  color={
                    props?.isDisabled
                      ? 'secondary.500'
                      : variant === 'dark' || variant === 'empty-dark'
                      ? 'orange.400'
                      : 'danger.700'
                  }
                />
              }
              _disabled={{
                opacity: 1,
              }}
              {...formLabelProps}
            >
              {label}
            </FormLabel>
          )}
          {rightLabel}
        </Flex>
      )}
      {description && description}
      {input}
      {!hideErrorMessage && (
        <FormErrorMessage
          color={
            variant === 'dark' || variant === 'empty-dark'
              ? 'orange.400'
              : 'danger.300'
          }
          position={errorPosition}
          whiteSpace="pre-wrap"
          textAlign="left"
          {...formErrorMessageProps}
        >
          {errorMessage}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default InputLayout;
