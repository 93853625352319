import { t } from 'i18next';

export const APPROPRIATE_CLASS_ID = 'appropriate-class';
export const NO_CLASSES_MESSAGE = 'no-classes';
export const NO_GRADES_MESSAGE = t('validationMessages.atLeastOne', {
  itemName: 'grade level',
  from: 'grade levels list',
});
export const NO_ACTIVITY_MESSAGE = t('validationMessages.atLeastOne', {
  itemName: 'activity',
  from: 'planner',
});
export const DESKTOP_TAB_PANEL_PADDING = '32px';
export const TABLET_TAB_PANEL_PADDING = '24px';
