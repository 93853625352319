import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';
import { get } from 'lodash-es';
import React, { memo } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { types } from './duck';
import { FormCheckboxProps } from './duck/types';

const BaseCheckbox: React.FC<types.FormCheckboxProps> = ({
  label,
  defaultValue = false,
  isRequired = false,
  gap = 4,
  size = 'lg',
  onChange,
  onBlur,
  isChecked,
  innerRef,
  errorMessage,
  value,
  checkboxProps,
  isDisabled,
  isDisabledStable,
  ...rest
}) => {
  return (
    <FormControl isInvalid={!!errorMessage} isRequired={isRequired} {...rest}>
      <Checkbox
        {...(isDisabledStable && { 'data-disabled-stable': true })}
        isDisabled={isDisabled || isDisabledStable}
        gap={gap}
        size={size}
        ref={innerRef}
        value={value}
        isChecked={!!value}
        onChange={onChange}
        onBlur={onBlur}
        {...checkboxProps}
      >
        {typeof label === 'string' ? <Text variant="s2">{label}</Text> : label}
      </Checkbox>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};

const CheckboxWithContext: React.FC<FormCheckboxProps> = ({
  name,
  defaultValue,
  onInputChange,
  ...props
}) => {
  const { control } = useFormContext();
  const {
    field: { onBlur, onChange, name: fieldName, ref, value },
    formState: { errors },
  } = useController({ name, defaultValue, control });
  const errorMessage = get(errors, `${fieldName}.message`) as unknown as string;

  return (
    <BaseCheckbox
      {...props}
      onBlur={onBlur}
      onChange={onInputChange || onChange}
      name={fieldName}
      errorMessage={errorMessage}
      innerRef={ref}
      value={value}
    />
  );
};
const FormCheckbox: React.FC<types.FormCheckboxProps> = ({ ...rest }) => {
  if (rest.name) {
    return <CheckboxWithContext {...rest} />;
  }

  return <BaseCheckbox {...rest} />;
};

export default memo(FormCheckbox);
