export default {
  root: '/login',
  login: {
    qrCode: '/login/qr-code',
    adoption: '/login/review/:eventCode',
    preview: '/login/preview/:urlCode?',
    forgotPassword: '/login/forgot-password',
    passwordReset: '/login/reset-password',
    systemSelect: '/login/sso/select-system',
    systemSelectLti: '/login/lti/select-system',
    stemOld: '/login/sso/stemScopes',
    error: '/login/error',
    registration: {
      adoption: '/login/adoption/registration',
      parent: '/login/parent/registration',
      preview: '/login/preview/registration',
    },
  },
};
