import { useCurrentRouter } from '../routers';
import React, { useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from '@lon/login/configs';
import { clearStorage } from '@lon/shared/utils';
import { IMPORTANT_LOCAL_STORAGE_KEYS } from '@lon/shared/constants';

const Root: React.FC = () => {
  const router = useCurrentRouter();

  useEffect(() => {
    clearStorage({
      exceptions: IMPORTANT_LOCAL_STORAGE_KEYS,
    });
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HelmetProvider>
          <React.Suspense fallback={null}>
            <RouterProvider router={router} />
          </React.Suspense>
        </HelmetProvider>
      </PersistGate>
    </Provider>
  );
};

export default Root;
