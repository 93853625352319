import { AclWrapper } from '../acl-wrapper';
import { useReactiveVar } from '@apollo/client';
import { Flex } from '@chakra-ui/react';
import { isEmpty } from 'lodash-es';
import React, { useEffect, useMemo, useState } from 'react';
import { Spinner } from '@lon/shared/components';
import {
  IMPERSONATE_WRAPPER_HEIGHT_REM,
  IMPERSONATE_WRAPPER_WIDTH_REM,
  NETWORK_ERROR_CODES,
} from '@lon/shared/constants';
import { AclContext } from '@lon/shared/contexts';
import { ApplicationEnum, ProfileTypeEnum } from '@lon/shared/enums';
import {
  useAuth, // useInvalidateStorages
} from '@lon/shared/hooks';
import {
  AclApplication,
  UserTypeEnum,
  useGetAclApplicationsQuery,
  useGetDistrictSettingsQuery,
} from '@lon/shared/requests';
import {
  REFETCH_APP_SETTINGS,
  hashValue,
  mergeAdminAclSettings,
  storeLoginData,
} from '@lon/shared/utils';
import { usePreferences } from './duck';
import { BootstrapAppInterface } from './duck/types';
import useUserRolesLookUp from './duck/use-user-roles-look-up';

const profileTypeByApplication = {
  [ApplicationEnum.ADMIN]: ProfileTypeEnum.ADMIN,
  [ApplicationEnum.PLATFORM_ADMIN]: ProfileTypeEnum.PLATFORM_ADMIN,
};

export const BootstrapApp: React.FC<BootstrapAppInterface> = ({
  children,
  application,
}) => {
  const shouldRefetch = useReactiveVar(REFETCH_APP_SETTINGS);
  // useInvalidateStorages({ application });
  const [auth, setAuth] = useAuth();
  const [appLoading, setAppLoading] = useState(true);

  const {
    data,
    loading: aclApplicationsLoading,
    error,
    called,
    refetch,
  } = useGetAclApplicationsQuery({
    onCompleted: (data) => {
      hashValue(data.applicationSettings?.value?.user?.userId).then((value) =>
        localStorage.setItem('signature', value)
      );
      if (application === ApplicationEnum.PLATFORM_ADMIN) {
        setAppLoading(false);
      }

      storeLoginData(data?.applicationSettings?.value);
    },
    onError: () => {
      setAppLoading(false);
    },
  });
  usePreferences();

  const { data: settingsData, loading: settingsLoading } =
    useGetDistrictSettingsQuery({
      variables: {
        withUMSettings: false,
        withRosterSettings: false,
        withWizardStatus: true,
        withContentGroups: false,
        withAISettings: false,
        withKnowledgeSettings: false,
      },
      skip: application === ApplicationEnum.PLATFORM_ADMIN,
      onCompleted: () => {
        setAppLoading(false);
      },
      onError: () => {
        setAppLoading(false);
      },
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    if (!shouldRefetch) {
      if (!called) {
        refetch();
      }
    } else {
      if (called) {
        refetch();
        REFETCH_APP_SETTINGS(false);
      }
    }
  }, [shouldRefetch, called]);

  const { lookUpRolesLoading, rolesLookUp } = useUserRolesLookUp(
    data?.applicationSettings?.value?.user?.userRoles,
    !data ||
      (data &&
        data?.applicationSettings?.value?.user?.type === UserTypeEnum.Student)
  );

  const isSessionExpired =
    error?.message === NETWORK_ERROR_CODES.sessionExpired;
  const isPasswordExpired =
    error?.message === NETWORK_ERROR_CODES.passwordExpired;
  const isSessionInvalidated =
    error?.message === NETWORK_ERROR_CODES.sessionInvalidated;
  const showCredentialsModal =
    isSessionExpired || isPasswordExpired || isSessionInvalidated;

  React.useEffect(() => {
    const user = data?.applicationSettings?.value?.user;
    const hasUser = !isEmpty(user);

    if (hasUser && !lookUpRolesLoading) {
      setAuth({
        user: {
          type: user?.type,
          firstName: user?.firstName,
          lastName: user?.lastName,
          username: user?.username,
          schoolIds: user?.schoolIds,
          districtId: user?.districtId,
          userId: user?.userId,
          gradeLevel: user?.gradeLevel,
          email: user?.email,
          purpose: user?.purpose,
          permissions: user?.permissions,
          availableSystems: user?.availableSystems,
          userRoles: rolesLookUp!,
          state: user?.state,
          profileType: user?.profileType,
          districtUuid: user?.districtUuid,
          impersonated: user?.impersonated,
        },
        isLoggedIn: true,
        isAuthenticating: false,
        profileType: profileTypeByApplication[application],
      });
      setAppLoading(false);
    }
  }, [data?.applicationSettings?.value, rolesLookUp, lookUpRolesLoading]);

  const aclContextValue = useMemo(
    () => ({
      value: (data
        ? mergeAdminAclSettings(application, data, settingsData)
        : []) as AclApplication[],
      loading: aclApplicationsLoading || settingsLoading,
    }),

    [data, aclApplicationsLoading, settingsData, settingsLoading]
  );

  if ((appLoading && !showCredentialsModal) || lookUpRolesLoading) {
    return (
      <Flex
        direction="column"
        h={
          (auth as any)?.data?.user?.impersonate
            ? `calc(100vh - ${IMPERSONATE_WRAPPER_HEIGHT_REM}rem)`
            : '100vh'
        }
        w={
          (auth as any)?.data?.user?.impersonate
            ? `calc(100vw - ${IMPERSONATE_WRAPPER_WIDTH_REM}rem)`
            : '100vw'
        }
        maxH="100vh"
        overflow="hidden"
        backgroundColor="white"
        position="relative"
      >
        <Flex justifyContent="center" alignItems="center" flex="1">
          <Spinner variant="dark" />
        </Flex>
      </Flex>
    );
  }

  return (
    <AclContext.Provider value={aclContextValue}>
      <AclWrapper
        application={application}
        loading={
          aclApplicationsLoading || settingsLoading || lookUpRolesLoading
        }
        showCredentialsModal={showCredentialsModal}
      >
        {children}
      </AclWrapper>
    </AclContext.Provider>
  );
};
