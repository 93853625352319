import { Copyright } from '../copyright';
import { Box, Flex } from '@chakra-ui/react';
import React from 'react';

const UnauthorizedFooter: React.FC = () => (
  <Box
    id="footer"
    as="footer"
    aria-label="footer"
    boxShadow="0px 0px 20px 0px rgba(0, 0, 0, 0.15)"
  >
    <Flex justify="center" align="center" m="0 auto" py="15px">
      <Copyright />
    </Flex>
  </Box>
);

export default UnauthorizedFooter;
