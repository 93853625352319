import { get } from 'lodash-es';
import {
  CODING24,
  defaultDistrictSettings,
  districtSettings,
} from '@lon/shared/constants';
import { ApplicationEnum, DistrictPermission } from '@lon/shared/enums';
import {
  BehaviourEnum,
  GetAclApplicationsQuery,
  GetDistrictSettingsQuery,
} from '@lon/shared/requests';
import { ModuleEnum, OperationEnum } from '@lon/shared/types';

const codingModule = {
  name: ModuleEnum.coding,
  operations: [
    {
      name: OperationEnum.view,
      behaviour: BehaviourEnum.Display,
    },
  ],
};
const learningBotModule = {
  name: ModuleEnum.learningBot,
  operations: [
    {
      name: OperationEnum.access,
      behaviour: BehaviourEnum.Display,
    },
  ],
};

type Permission = { name: string; behaviour: BehaviourEnum };

const getSettingsModulePlaceholder = (
  data?: GetDistrictSettingsQuery,
  application?: ApplicationEnum
) => {
  const checkPermission = (opts: {
    module: ModuleEnum;
    definedSettings: { key: string; name: OperationEnum }[];
  }) => {
    settings.push({
      name: opts.module,
      operations: opts?.definedSettings?.reduce<Permission[]>(
        (acc, { key, name }) => {
          if (get(data, key)) {
            return [...acc, { name, behaviour: BehaviourEnum.Display }];
          } else {
            return [...acc, { name, behaviour: BehaviourEnum.Hidden }];
          }
        },
        []
      ),
    });
  };

  const settings = [];

  checkPermission({
    module: ModuleEnum.classes,
    definedSettings: districtSettings.rosterSettings,
  });

  checkPermission({
    module: ModuleEnum.students,
    definedSettings: districtSettings.umSettings,
  });

  if (application !== ApplicationEnum.MATHNATION_SUIT) {
    checkPermission({
      module: ModuleEnum.learningBot,
      definedSettings: districtSettings.learningBot,
    });

    if (application === ApplicationEnum.TEACHER_SUIT) {
      checkPermission({
        module: ModuleEnum.lessonPlanner,
        definedSettings: districtSettings.lessonPlanner,
      });
    } else {
      settings.push({
        name: ModuleEnum.lessonPlanner,
        operations: [
          { name: OperationEnum.access, behaviour: BehaviourEnum.Display },
        ],
      });
    }
  }

  return settings;
};

type MergeSuiteAclSettings = (
  application: any,
  data?: GetAclApplicationsQuery,
  settings?: GetDistrictSettingsQuery
) => any;

export const mergeSuiteAclSettings: MergeSuiteAclSettings = (
  application,
  data,
  settings = defaultDistrictSettings
) => {
  const hasCodingAccess = settings?.activeContentGroups?.some(
    ({ id }) => id === CODING24
  );

  if (
    [
      ApplicationEnum.MATHNATION_SUIT,
      ApplicationEnum.TEACHER_SUIT,
      ApplicationEnum.CAMPUS_LEADER_SUIT,
      ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT,
      ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT,
    ].includes(application)
  ) {
    const districtSettingsModules = getSettingsModulePlaceholder(
      settings,
      application
    );

    return [
      ...(data?.aclApplications || []),
      {
        modules: [
          ...districtSettingsModules,
          hasCodingAccess ? codingModule : {},
        ],
        name: null,
        permissionName:
          application === ApplicationEnum.MATHNATION_SUIT
            ? DistrictPermission.MATHNATION_SUIT
            : DistrictPermission.TEACHER_SUIT,
      },
    ];
  } else if (application === ApplicationEnum.PARENT_SUIT) {
    return [
      ...(data?.aclApplications || []),
      {
        modules: [{}],
        name: null,
        permissionName: DistrictPermission.STUDENT_SUIT,
      },
    ];
  } else if (application === ApplicationEnum.STUDENT_SUIT) {
    const canUSeLearningBot =
      settings?.districtSettingsKnowledgeBase?.studentCanUsePerryBot;
    return [
      ...(data?.aclApplications || []),
      {
        modules: [
          hasCodingAccess ? codingModule : {},
          canUSeLearningBot ? learningBotModule : {},
        ],
        name: null,
        permissionName: DistrictPermission.STUDENT_SUIT,
      },
    ];
  }

  return data?.aclApplications;
};
