export function parseJSON<ReturnType extends object>(
  data: string,
  defaultValue?: any
): ReturnType;

export function parseJSON(data: string, defaultValue?: any): {};

export function parseJSON(data: string, defaultValue?: any) {
  let parsed = defaultValue;

  if (data && isJson(data)) {
    try {
      parsed = JSON.parse(data);
    } catch (e) {
      console.error(e);
    }
  }

  return parsed;
}

function isJson(str: string): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
