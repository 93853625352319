// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ThemeEnum } from '@lon/shared/enums';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { getThemeStyleValue } from '@lon/shared/utils';

export const getPopover = (theme: ThemeEnum) => {
  const contrastTheme = getThemeStyleValue(
    { normal: {}, inverted: {} },
    {
      normal: {
        bg: 'secondary.1000',
        color: 'white',
        '[type="button"]': {
          bgColor: 'white',
          color: 'secondary.1000',
          _hover: {
            opacity: 0.7,
          },
          _focusVisible: {
            boxShadow: '0 0 0 4px var(--chakra-colors-teal-500)',
          },
        },
      },
      inverted: {
        bg: 'white',
        color: 'secondary.1000',
        '[type="button"]': {
          bgColor: 'secondary.1000',
          color: 'white',
          _hover: {
            opacity: 0.7,
          },
          _focusVisible: {
            boxShadow: '0 0 0 4px var(--chakra-colors-teal-500)',
          },
        },
      },
    }
  )(theme);

  return {
    defaultProps: {
      variant: 'primary',
    },
    baseStyle: {
      content: {
        p: 4,
        borderRadius: '4px',
        border: 'none',
        boxShadow: '0 1px 4px 0 rgba(0,0,0,0.25) !important',
        _focus: {
          boxShadow: '0 1px 4px 0 rgba(0,0,0,0.25) !important',
        },
      },
    },
    variants: {
      primary: {
        content: {
          bg: 'primary.1000',
          color: 'white',
          ...contrastTheme.normal,
        },
      },
      secondary: {
        content: {
          bg: 'white',
          color: 'blue.500',
          ...contrastTheme.inverted,
        },
      },
      admin: {
        popper: {
          p: 6,
        },
        content: {
          bg: 'white',
          color: 'blue.500',
          p: 6,
          ...contrastTheme.inverted,
        },
        header: {
          p: 0,
          borderBottom: 0,
          marginBottom: 4,
        },
        body: {
          p: 0,
        },
        closeButton: {
          top: 5,
          right: 5,
        },
      },
      platformAdmin: {
        content: {
          bg: 'white',
          px: 6,
          py: 5,
          ...contrastTheme.inverted,
        },
        header: {
          p: 0,
          borderBottom: 0,
          marginBottom: 3,
        },
        closeButton: {
          top: 5,
          right: 5,
        },
      },
      tableLegend: {
        content: {
          bg: 'white',
          color: 'primary.800',
          p: 6,
          pt: 5,
          ...contrastTheme.inverted,
        },
        header: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 0,
          borderBottom: 0,
          marginBottom: 2.5,
        },
        body: {
          p: 0,
        },
        closeButton: {
          position: 'unset',
        },
      },
      tooltipLight: {
        content: {
          borderRadius: 10,
          color: 'black',
          backgroundColor: 'white',
          boxShadow: '0 10px 20px rgba(0,0,0,.15);',
          ...contrastTheme.inverted,
        },
        body: {
          p: 0,
        },
      },
      tooltipDark: {
        arrow: {
          '--popper-arrow-bg': '#1A2C49',
        },
        content: {
          py: 2,
          px: 4,
          color: 'white',
          borderRadius: 10,
          backgroundColor: '#1A2C49',
          boxShadow: '0 10px 20px rgba(0,0,0,.15);',
          ...contrastTheme.normal,
        },
        body: {
          p: 0,
        },
      },
    },
  };
};
