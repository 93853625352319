import { Text } from '@chakra-ui/react';
import { get } from 'lodash-es';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { CheckboxList } from '../../../../../../../checkbox-list';
import { FormSelect } from '../../../../../../../form-units';
import { useGetGradesQuery } from '@lon/shared/requests';
import { types } from './duck';

const SchoolInput: React.FC<types.SchoolInputProps> = ({
  schoolsLoading,
  schoolsOptions,
  ...props
}) => {
  const { t } = useTranslation();
  const selectedSchools = useWatch({ name: 'schools' });
  const {
    setValue,
    trigger,
    formState: { errors },
  } = useFormContext();
  const gradeLevelsErrorMessage = get(
    errors,
    `schools.${props.index}.schoolGradeLevels.message`
  );
  const schoolId = selectedSchools[props.index]?.schoolId;

  const { data: gradesData, loading: gradesLoading } = useGetGradesQuery({
    variables: {
      schoolId,
    },
    skip: !schoolId,
  });

  const gradeLevels =
    gradesData?.grades?.grades
      ?.map((grade) => ({
        label: Number(grade) > 0 ? `Grade ${grade}` : grade,
        value: grade,
      }))
      .sort((a, b) => {
        const first = a.label;
        const second = b.label;
        // TODO: update hardcoded 'en' to Spanish when change to Spanish is implemented
        return first.localeCompare(second, 'en', { numeric: true });
      }) || [];

  const getSchoolsOptions = () => {
    const selectedSchoolsIds = selectedSchools.map(
      ({ schoolId }: { schoolId: string }) => schoolId
    );
    const currentSchool = schoolsOptions?.find(
      ({ value }) => value === schoolId
    );

    return [
      ...(currentSchool ? [currentSchool] : []),
      ...(schoolsOptions
        ? schoolsOptions.filter(
            (school) => !selectedSchoolsIds.includes(school.value)
          )
        : []),
    ];
  };

  const handleChangeIndeterminateCheckbox = (checked: boolean) => {
    setValue(
      `schools.${props.index}.schoolGradeLevels`,
      checked
        ? gradeLevels?.map((grade) => grade?.value || '')
        : new Array(gradeLevels.length).fill('')
    );
    trigger(`schools.${props.index}.schoolGradeLevels`);
  };

  const handleChangeCheckboxList = (
    value: number,
    checked: boolean,
    index: number
  ) => {
    setValue(
      `schools.${props.index}.schoolGradeLevels.${index}`,
      checked ? value : ''
    );

    trigger(`schools.${props.index}.schoolGradeLevels`);
  };

  return (
    <>
      <FormSelect
        display="flex"
        lineHeight={10}
        isLoading={schoolsLoading}
        isRequired
        returnObject={false}
        options={getSchoolsOptions()}
        noOptionsMessage={() => t('createAssignmentDrawer.noSchoolsMessage')}
        onChange={() => {
          setValue(`schools.${props.index}.schoolGradeLevels`, []);
        }}
        {...(props as any)}
        name={`schools.${props.index}.schoolId`}
      />
      {schoolId && (
        <>
          <CheckboxList
            isLoading={gradesLoading}
            data={gradeLevels}
            selectedCount={
              selectedSchools?.[props.index]?.schoolGradeLevels?.filter(Boolean)
                .length
            }
            errorMessage={gradeLevelsErrorMessage as any}
            title={t('createAssignmentDrawer.gradeLevelsTab')}
            name={`schools.${props.index}.schoolGradeLevels`}
            loadingMessage={t('createAssignmentDrawer.loadingGradeLevels')}
            mt="6"
            onIndeterminateCheckboxChange={handleChangeIndeterminateCheckbox}
            onChange={handleChangeCheckboxList}
            renderCheckboxLabel={(label) => (
              <Text
                className="notranslate"
                variant="n3"
                ml={-2}
                color="primary.800"
              >
                {label}
              </Text>
            )}
            sx={{ li: { mb: 3 }, '.chakra-accordion__panel': { pb: 1 } }}
          />
        </>
      )}
    </>
  );
};

export default SchoolInput;
