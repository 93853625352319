import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import qrCodeReducer from './qr-code';

const imageTag = process.env?.['NX_IMAGE_TAG'] || '';

const persistConfig = {
  key: `login[${imageTag}]`,
  storage,
  whitelist: ['filters'],
  blacklist: ['auth'],
};

const rootReducer = combineReducers({
  qrCode: qrCodeReducer,
});

export default persistReducer(persistConfig, rootReducer);
