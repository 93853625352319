const videoTitleManager = (function () {
  let videoTitle = '';

  return {
    setVideoTitle: function (title: string) {
      videoTitle = title;
    },
    getVideoTitle: function () {
      return videoTitle;
    },
  };
})();

export default videoTitleManager
