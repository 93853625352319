import { Asterisk } from '../../asterisk';
import { Icon } from '../../icon';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Text,
} from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect, { components } from 'react-select';
import { MultiSelectOption } from '@lon/shared/types';
import DropdownIndicator from './components/DropdownIndicator';
import { styles, types } from './duck';
import { Input, MenuList as VirtualizedMenu } from './components';

const Select = <
  // eslint-disable-next-line
  TOption extends any = MultiSelectOption,
  IsMulti extends boolean = false
>({
  isLoading,
  isDisabledStable,
  disabled,
  name,
  label,
  options,
  isRequired,
  placeholder,
  isMulti,
  menuPlacement = 'auto',
  errorMessage,
  testId,
  onBlur,
  onChange,
  value,
  defaultValue,
  iconVariant,
  variant = 'base',
  showClearIndicator = false,
  disableDropDown = false,
  selectSizes,
  topRightLabel,
  captureMenuScroll = false,
  formControlProps,
  formLabelProps,
  innerRef,
  optionsWithDescription,
  size = 'n',
  isInsideDrawer = false,
  virtualized = false,
  showCustomPlaceholder = false,
  hideErrorMessage = false,
  formErrorMessageProps,
  ...rest
}: types.SelectProps<TOption, IsMulti>): React.ReactElement => {
  const { t } = useTranslation();
  const selectRef = React.useRef(null);

  React.useEffect(() => {
    if (selectRef.current && innerRef) {
      innerRef(selectRef.current);
    }
  }, [selectRef.current]);

  const MenuList = useCallback((props: any) => {
    return virtualized ? (
      <VirtualizedMenu selectRef={selectRef} {...props} isMulti />
    ) : (
      <components.MenuList {...props} children={props.children} isMulti />
    );
  }, []);

  const customStyles = isDisabledStable
    ? styles.disabledStableCustomStyles
    : variant === 'adoptionLogin'
    ? styles.getAdoptionLoginStyles({
        disabled,
        errorMessage,
        showClearIndicator,
        selectSizes,
        size,
        isInsideDrawer,
        showCustomPlaceholder,
      })
    : styles.getCustomStyles({
        disabled,
        errorMessage,
        variant,
        showClearIndicator,
        selectSizes,
        size,
        isInsideDrawer,
        showCustomPlaceholder,
      });

  return (
    <FormControl
      isDisabled={disabled}
      isInvalid={!!errorMessage}
      isRequired={isRequired && !isDisabledStable}
      variant={disabled ? 'disabled' : variant}
      data-testid={testId}
      {...formControlProps}
    >
      <Flex justifyContent="space-between" direction="row" alignItems="center">
        {label && (
          <FormLabel
            htmlFor={name}
            color="primary.800"
            requiredIndicator={
              <Asterisk {...(disabled ? { color: 'secondary.500' } : {})} />
            }
            _disabled={{
              opacity: 1,
              color: 'secondary.500',
            }}
            {...formLabelProps}
          >
            {label}
          </FormLabel>
        )}
        {topRightLabel && topRightLabel}
      </Flex>
      <ReactSelect
        defaultValue={defaultValue}
        inputId={name}
        menuPlacement={menuPlacement}
        styles={customStyles}
        name={name}
        aria-label={
          rest['aria-label']
            ? rest['aria-label']
            : typeof label === 'string'
            ? label
            : ''
        }
        value={
          typeof value === 'string'
            ? options.find((opt) => (opt as any).value === value)
            : value
        }
        onChange={onChange}
        onBlur={onBlur}
        ref={selectRef}
        options={options}
        isLoading={isLoading}
        isDisabled={disabled || isDisabledStable || isLoading}
        isMulti={isMulti}
        closeMenuOnSelect={!isMulti}
        placeholder={<em>{placeholder || t('multiSelectPlaceholder')}</em>}
        captureMenuScroll={captureMenuScroll}
        components={{
          DropdownIndicator: (props) =>
            disableDropDown ? null : (
              <DropdownIndicator {...props} iconVariant={iconVariant} />
            ),
          Input,
          Option: ({ children, ...props }) => {
            const Icon = (props.data as any).Icon;
            if (optionsWithDescription) {
              const options = (children as string)?.split('<br>');
              return (
                <components.Option {...props}>
                  {Icon ? Icon : null}
                  {<Text variant="n3">{options[0]}</Text>}
                  {
                    <Text variant="s3" color="primary.400">
                      {options[1]}
                    </Text>
                  }
                </components.Option>
              );
            } else {
              return (
                <components.Option {...props}>
                  {Icon ? Icon : null}
                  {children}
                </components.Option>
              );
            }
          },
          SingleValue: ({ children, ...props }) => {
            const Icon = (props.data as any).Icon;
            if (optionsWithDescription) {
              const options = (children as string)?.split('<br>');
              return (
                <components.SingleValue {...props}>
                  {Icon ? Icon : null}
                  {options[0]}
                </components.SingleValue>
              );
            } else {
              return (
                <components.SingleValue {...props}>
                  {Icon ? Icon : null}
                  {children}
                </components.SingleValue>
              );
            }
          },
          MenuList: MenuList,
          MultiValueRemove: (props) => {
            return (
              <Flex alignItems="center">
                <components.MultiValueRemove {...props}>
                  <IconButton
                    size="xs"
                    bg="red"
                    aria-label="delete"
                    icon={<Icon name="deleteCircleOutlined" />}
                    variant="round"
                    minW="unset"
                    height="unset"
                    background="unset"
                    backgroundColor="unset"
                    _hover={{ backgroundColor: 'unset' }}
                  />
                </components.MultiValueRemove>
              </Flex>
            );
          },
          ClearIndicator: (props) => {
            const { innerProps, getStyles } = props;

            return (
              <IconButton
                {...(innerProps as any)}
                style={getStyles('clearIndicator', props)}
                size="xs"
                aria-label="Clear"
                icon={<Icon name="deleteCircleOutlined" />}
                variant="round"
                backgroundColor="unset"
                _hover={{ backgroundColor: 'unset' }}
              />
            );
          },
          ...(disableDropDown
            ? {
                Menu: () => null,
                MenuList: () => null,
              }
            : {}),
        }}
        {...rest}
      />
      {!hideErrorMessage && (
        <FormErrorMessage
          color={variant === 'dark' && !disabled ? 'orange.400' : 'danger.300'}
          {...formErrorMessageProps}
        >
          {errorMessage}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};

export default Select;
