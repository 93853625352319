import debounce from "lodash-es/debounce";

export const updateContentPlayerIframe = debounce(
	(iframe: HTMLIFrameElement, height: number) => {
		iframe.height = height.toString();
	},
	300
);

export const isFrostDomain = (domain?: string) => {
	// learningmate.co & learningmate.in are the frost DEV and QA domains
	return domain && (domain.includes("frostplatform.com")
		|| domain.includes("learningmate.co")
		|| domain.includes("learningmate.in"));
};