const textNodeMap = new WeakMap<
  Text,
  { original: string; translated?: string }
>();
const translatedNodes = new Set<Text>();

export const processTextNodes = async (
  modifyTextFn: ((texts: string[]) => Promise<string[]>) | null,
  revert = false
): Promise<void> => {
  const textNodes: Text[] = [];

  const walker = document.createTreeWalker(
    document.body,
    NodeFilter.SHOW_TEXT,
    {
      acceptNode: (node: Node) => {
        if (
          !(node as Text).nodeValue?.trim() ||
          ['SCRIPT', 'STYLE'].includes(
            (node.parentNode as HTMLElement)?.tagName || ''
          )
        ) {
          return NodeFilter.FILTER_REJECT;
        }
        return NodeFilter.FILTER_ACCEPT;
      },
    }
  );

  while (walker.nextNode()) {
    const node = walker.currentNode as Text;
    const existingEntry = textNodeMap.get(node);

    if (revert) {
      if (existingEntry) {
        node.nodeValue = existingEntry.original;
        translatedNodes.delete(node);
      }
    } else {
      if (existingEntry && !translatedNodes.has(node)) {
        node.nodeValue = existingEntry.original;
        textNodes.push(node);
      } else if (!existingEntry) {
        textNodeMap.set(node, { original: node.nodeValue || '' });
        textNodes.push(node);
      }
    }
  }

  if (textNodes.length === 0 || revert || !modifyTextFn) {
    return;
  }

  const originalTextValues = textNodes.map((node) => node.nodeValue || '');
  const modifiedTexts = await modifyTextFn(originalTextValues);

  textNodes.forEach((node, index) => {
    textNodeMap.set(node, {
      original: textNodeMap.get(node)!.original,
      translated: modifiedTexts[index],
    });
    node.nodeValue = modifiedTexts[index];
    translatedNodes.add(node);
  });
};

export const observeTextChanges = (
  modifyTextFn: (texts: string[]) => Promise<string[]>,
  isChecked: boolean
) => {
  const observerCallback = async (mutationsList: MutationRecord[]) => {
    let newTextAdded = false;

    for (const mutation of mutationsList) {
      if (mutation.type === 'childList') {
        newTextAdded = true;
        break;
      }
    }

    if (newTextAdded && isChecked) {
      await processTextNodes(modifyTextFn);
    }
  };

  const observer = new MutationObserver(observerCallback);
  observer.observe(document.body, { childList: true, subtree: true });

  return observer;
};

const baseUrl =
  'https://stem.dev.stemscopes-v4-dev.aws.acceleratelearning.com/api/translate/gemini';

export const modifyTextBatch = async (texts: string[]): Promise<string[]> => {
  try {
    const response = await fetch(baseUrl, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({ q: texts, target: 'es' }),
    });

    if (!response.ok) {
      throw new Error(`API error: ${response.status}`);
    }

    const data = (await response.json()) || texts;
    return data;
  } catch (error) {
    console.error(error);
    return texts;
  }
};

// export const modifyTextBatch = async (texts: string[]) => {
//   console.log({ texts });
//   return texts.map((text) => `[Translated] ${text}`);
// };
