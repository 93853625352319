export const Textarea = {
  baseStyle: {
    borderWidth: '1px',
    '&::placeholder': {
      color: 'secondary.400',
      fontStyle: 'italic',
    },
    _disabled: {
      opacity: 1,
      background: 'secondary.100',
      borderColor: 'primary.50',
      color: 'blue.400',

      _hover: {
        opacity: 1,
        background: 'secondary.100',
        borderColor: 'primary.50',
        color: 'primary.300',
      },
      '&::placeholder': {
        color: 'primary.400',
      },
    },
    _focus: {
      borderColor: 'blue.100',
      outline: 'none',
      boxShadow: 'none',
    },

    _focusVisible: {
      borderColor: 'blue.100',
      outline: 'none',
      boxShadow: 'none',
    },
  },
  variants: {
    fileName: {
      _disabled: {
        opacity: 1,
        background: 'secondary.50',
        borderColor: 'secondary.300',
        color: 'secondary.400',
      },
    },
  },
};
