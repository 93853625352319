import { Select as ChakraSelect } from '../../inputs';
import { get, intersectionWith } from 'lodash-es';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { ActionMeta } from 'react-select';
import { SelectProps } from './duck/types';

const Select: React.FC<SelectProps> = ({
  name,
  defaultValue,
  transformValues,
  returnObject = true,
  onChange,
  isDisabled = false,
  disabled = false,
  hideErrorMessage = false,
  ...rest
}) => {
  const { control } = useFormContext();

  const {
    field: { onBlur, onChange: onChangeHookForm, name: fieldName, value, ref },
    formState: { errors },
  } = useController({ name, control, defaultValue });

  const errorMessage = get(errors, `${fieldName}.message`) as unknown as string;

  const changeHandler = (values: any, meta: ActionMeta<any>) => {
    const plainValue = Array.isArray(values)
      ? values.map((value) => value.value)
      : values?.value;
    const returnedValue = returnObject ? values : plainValue;

    if (typeof transformValues === 'function') {
      const transformedValues = transformValues(returnedValue, meta);

      onChangeHookForm(transformedValues);
      onChange && onChange(transformedValues);
    } else {
      onChangeHookForm(returnedValue);
      onChange && onChange(returnedValue);
    }
  };

  const computedValue = returnObject
    ? value
    : rest.isMulti
    ? intersectionWith(rest.options, value, (a, b) => a.value === b)
    : rest.options?.find((option) => option.value === value);

  return (
    <ChakraSelect
      {...rest}
      disabled={disabled || isDisabled}
      name={name}
      errorMessage={errorMessage}
      onChange={changeHandler}
      value={computedValue || null}
      onBlur={onBlur}
      innerRef={ref}
      hideErrorMessage={hideErrorMessage}
    />
  );
};

export default Select;
