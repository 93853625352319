import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  ServerError,
  ServerParseError,
  from,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createStandaloneToast } from '@chakra-ui/react';
import { createUploadLink } from 'apollo-upload-client';
import fetch from 'cross-fetch';
import { isEmpty } from 'lodash-es';
import {
  MODALS,
  NETWORK_ERROR_CODES,
  isMainEnv,
  isProdEnv,
} from '@lon/shared/constants';
import { ErrorCode, ErrorParams } from '@lon/shared/types';
import { getErrorMessageByCode } from './getErrorMessageByCode';
import { getLastDistrictBySignature } from './lastApplication';
import { logout } from './logout';
import { OPENED_MODAL, REFETCH_APP_SETTINGS } from './reactiveVars';
import { skipOperationToast } from './skipOperationToast';

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    if (response.errors) {
      for (const error of response.errors) {
        if (error?.extensions?.code === 401) {
          if (
            error?.extensions?.message === NETWORK_ERROR_CODES.passwordExpired
          ) {
            OPENED_MODAL(MODALS.PasswordUpdate);
          } else if (
            error?.extensions?.message === NETWORK_ERROR_CODES.sessionExpired
          ) {
            console.error('sessionExpired apolloclient', error);

            const savedDistrict = getLastDistrictBySignature();

            if (isEmpty(savedDistrict)) {
              logout({ keepPreviousUrl: true });
            } else {
              OPENED_MODAL(MODALS.SessionExpired);
            }
          } else {
            logout({ keepPreviousUrl: true });
          }
        }
      }
    }

    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (typeof headers === 'object') {
      if (headers.get('Application-Settings-Outdated')) {
        REFETCH_APP_SETTINGS(true);
      }
    }

    return response;
  });
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const typedNetworkError = networkError as ServerError | ServerParseError;
  const statusCode = typedNetworkError?.statusCode;

  if (statusCode === 401) {
    logout({ keepPreviousUrl: true });
  }

  if (graphQLErrors && !skipOperationToast(operation.operationName)) {
    graphQLErrors.forEach(({ path, extensions, message }) => {
      // create alerts only for messages that have a single element in the path
      // because if there are more path items, then the error is related to the form field
      // and should be handled with displayFormErrors function
      if (
        path &&
        path.length === 1 &&
        ![
          '_0_createVideoPostObject',
          '_0_helpPage',
          '_0_createAssignment',
        ].includes(String(path[0])) &&
        !isProdEnv() &&
        !isMainEnv()
      ) {
        createStandaloneToast({
          defaultOptions: {
            position: 'top',
            variant: 'solid',
          },
        }).toast({
          title: getErrorMessageByCode(
            extensions?.code as ErrorCode,
            extensions?.parameters as ErrorParams,
            message
          ),
          status: 'error',
          isClosable: true,
        });
      }
    });
  }
});

const httpLink = createUploadLink({
  uri:
    process.env['NX_GRAPHQL_API_URL'] ||
    `${window.location.origin}/api/graphql`,
  fetch: process.env.NODE_ENV === 'test' ? fetch : undefined,
  credentials: 'include',
});

const apolloClient = new ApolloClient({
  // @ts-ignore
  link: from([afterwareLink, errorLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      userCalendars: {
        // userCalendars, calendars are identified using calendarId
        keyFields: ['calendarId'],
      },
    },
  }),
});

export default apolloClient;
