import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  applicationSettingsSlice,
  authSlice,
  filtersSlice,
} from '@lon/shared/configs';
import sidebarReducer from './sidebar';

const imageTag = process.env?.['NX_IMAGE_TAG'] || '';

const persistConfig = {
  key: `admin[${imageTag}]`,
  storage,
  whitelist: ['filters'],
  blacklist: ['auth'],
};

const rootReducer = combineReducers({
  filters: filtersSlice.default,
  sidebar: sidebarReducer,
  auth: authSlice.default,
  applicationSettings: applicationSettingsSlice.default,
});

export default persistReducer(persistConfig, rootReducer);
