import { Flex, Link, ResponsiveValue, Text } from '@chakra-ui/react';
import { Property } from 'csstype';
import {
  isProdCluster,
  prodStemDomain,
  stemDomain,
} from '@lon/shared/constants';

interface Props {
  color?: string;
  fontSize?: ResponsiveValue<Property.FontSize> | undefined;
  fontWeight?: number;
  lineHeight?: ResponsiveValue<Property.LineHeight> | undefined;
  direction?: ResponsiveValue<Property.FlexDirection> | undefined;
  gap?: ResponsiveValue<Property.Gap> | undefined;
  justify?: ResponsiveValue<Property.JustifyContent> | undefined;
  align?: ResponsiveValue<Property.AlignItems> | undefined;
}

export const Copyright: React.FC<Props> = ({
  color = 'primary.800',
  fontSize = { base: '14px', md: 'sm' },
  fontWeight = 500,
  lineHeight = { md: 'short' },
  direction = { base: 'column', md: 'row' },
  gap = { base: 1, md: 8 },
  justify = 'center',
  align = 'center',
}) => {
  return (
    <Flex gap={gap} direction={direction} justify={justify} align={align}>
      <Flex gap={1.5} direction="row">
        <Text
          color={color}
          aria-hidden="true"
          fontSize={fontSize}
          lineHeight={lineHeight}
          fontWeight={fontWeight}
        >
          &copy; {new Date().getFullYear()}
        </Text>
        <Link
          href={isProdCluster() ? prodStemDomain : `${stemDomain}/home.php`}
          isExternal
          color={color}
          aria-label="Accelerate Learning Inc."
          textDecoration="underline"
          fontSize={fontSize}
          lineHeight={lineHeight}
          fontWeight={fontWeight}
        >
          Accelerate Learning Inc.
        </Link>
      </Flex>
      <Link
        href={
          isProdCluster() ? `${prodStemDomain}/terms` : `${stemDomain}/terms`
        }
        isExternal
        color={color}
        textDecoration="underline"
        fontSize={fontSize}
        lineHeight={lineHeight}
        fontWeight={fontWeight}
        aria-label="Terms and Conditions"
      >
        Terms and Conditions
      </Link>
    </Flex>
  );
};
