import { Icon } from '../icon';
import {
  Box,
  Link,
  Text,
  ToastId,
  chakra,
  useToast as useToastChakra,
} from '@chakra-ui/react';
import { ReactNode, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { ToastProps } from '@lon/shared/types';
import { icons } from './duck';

interface Toast {
  toast: (state: ToastProps, storeStateForUpdate?: boolean) => ToastId;
  closeToast: (id: ToastId) => void;
  updateToast: (id: ToastId, updates: Partial<ToastProps>) => void;
  closeAllToasts: () => void;
}

export function useToast(): Toast {
  const toast = useToastChakra();
  const toastPropsByIdRef = useRef<{ [id: ToastId]: ToastProps }>({});

  const createToast = (
    state: ToastProps,
    storeStateForUpdate?: boolean
  ): ToastId => {
    const {
      duration,
      isClosable = false,
      title,
      description,
      status,
      position = 'bottom',
      variant = 'error-light',
      firstLink = '',
      firstLinkText = '',
      secondLink = '',
      secondLinkText = '',
      render,
      containerStyle,
    } = state;

    const toastId = toast({
      title: (
        <chakra.span>
          {ReactHtmlParser(title as string) as ReactNode}
        </chakra.span>
      ),
      description: (
        <Box minW="280px" role="alert" aria-label={title as string}>
          <Text variant="s2" mb={1}>
            {description}
          </Text>
          {firstLink && (
            <Link textDecor="underline" href={firstLink} mr="180px">
              {firstLinkText}
            </Link>
          )}
          {secondLink && (
            <Link textDecor="underline" href={secondLink}>
              {secondLinkText}
            </Link>
          )}
        </Box>
      ),
      status,
      duration,
      position: position,
      isClosable,
      variant,
      icon: <Icon name={(icons as any)[variant as any]} />,
      containerStyle: {
        maxW: '500px',
        zIndex: 10000,
        ...containerStyle,
      },
      render,
    });

    if (storeStateForUpdate) {
      toastPropsByIdRef.current[toastId] = state;
    }

    return toastId;
  };

  const closeToast = (id: ToastId) => {
    toast.close(id);
    delete toastPropsByIdRef.current[id];
  };

  const updateToast = (id: ToastId, updates: Partial<ToastProps>) => {
    const currentProps = toastPropsByIdRef.current[id];
    const updatedProps = {
      ...currentProps,
      ...updates,
    };

    toast.update(id, updatedProps);

    toastPropsByIdRef.current[id] = updatedProps;
  };

  const closeAllToasts = () => {
    toast.closeAll();
    toastPropsByIdRef.current = {};
  };

  return {
    toast: createToast,
    closeToast,
    updateToast,
    closeAllToasts,
  };
}
